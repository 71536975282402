<template>
  <div>
    <portal to="title-page">
      <h1 v-if="tour">{{tour.name}}</h1>
    </portal>
    <tour-tabs v-if="tour" tab="rates" :tour="tour">
      <!-- <h2>Tarifas</h2> -->
      <v-data-table v-if="items != null"
        v-model="selected"
        :headers="tableHeaders"
        :items="items"
        item-key="id"
        :search="search"
        fixed-header
        class="elevation-1"
        :custom-filter="filterRates"
      >
        <!-- show-select -->
        <!-- :single-select="singleSelect" -->
        <template v-slot:top>
          <div class="table-top-bar">
            <!-- <v-card-title >Tarifas</v-card-title>
            <v-divider></v-divider> -->
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  label="Buscar"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-btn @click="$router.push({path:`/backend/admin/tours/${$route.params.tourId}/rates/create`})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Tarifa</v-btn>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
        </template>
        <template v-slot:item.startDate="{ item }">
          {{item.formatStartDate()}}
        </template>
        <template v-slot:item.endDate="{ item }">
          {{item.formatEndDate()}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon 
            class="mr-2"
            @click="$router.push({path:`/backend/admin/tours/${$route.params.tourId}/rates/${item.id}/edit?type=${item.type}`})"
          >
            {{mdiPencil}}
          </v-icon>
          <v-icon 
            class="mr-2"
            color="red"
            @click="deleteItem(item)"
          >
            {{mdiDelete}}
          </v-icon>
        </template>
      </v-data-table>
    </tour-tabs>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
// import TarifaTour from "@/models/TarifaTour.js";
//import Vue from "vue";
import Tour from "@/models/Tour";
import firebase from 'firebase/app';
import Vue from "vue";
import TourTabs from "@/components/admin/TourTabs";
Vue.use('tour-tabs', TourTabs);
import { mdiDelete, mdiPencil, mdiClose } from '@mdi/js';
// import { mdiHumanMaleChild, mdiBabyCarriage, mdiHumanMale } from '@mdi/js';
// import { mdiHumanCane  } from '@/assets/icons/mdi-icons-extended.js';
import 'firebase/firestore';
export default {
  name: "Rates",
  components:{TourTabs},
  data() {
    return {
      mdiDelete:mdiDelete,
      mdiPencil :mdiPencil,
      mdiClose :mdiClose,
      selected:[],
      tour:null,
      search:'',
      tableHeaders:[
        {
          text: 'Fecha inicio',
          align: 'start',
          sortable: true,
          value: 'startDate',
        },
        {
          text: 'Fecha fin',
          align: 'start',
          sortable: false,
          value: 'endDate',
        },
        {
          text: 'Tipo',
          align: 'start',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Adulto',
          align: 'start',
          sortable: false,
          value: 'adult',
          filterable:false
        },
        {
          text: 'A.Mayor',
          align: 'start',
          sortable: false,
          value: 'elderly',
          filterable:false
        },
        {
          text: 'Niño',
          align: 'start',
          sortable: false,
          value: 'child',
          filterable:false
        },
        {
          text: 'Infante',
          align: 'start',
          sortable: false,
          value: 'infant',
          filterable:false
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      tourModel: new Tour,
      items:null,
      localRates:null,
      regularRates:null,
      date : firebase.firestore.Timestamp.fromDate(new Date),
      filters:{
      },
      /**observadores*/
      seachLocalRatesAfterDate:null,
      seachRegularRatesAfterDate:null,
      /*typeRates: [
        {type:'local', text:'Local (Quintanarroenses)'},
        {type:'regular', text:'Regular (Nacional y Extranjero)'},
      ],*/
      snackbar:{
        show:false,
        color:'',
        message:''
      }
    };
  },
  methods: {
    setItems() {
      if (this.localRates !== null && this.regularRates !== null ) {
        this.items = this.localRates.concat(this.regularRates);
      } else {
        if (this.localRates !== null) {
          this.items = this.localRates
        }
        if (this.regularRates !== null) {
          this.items = this.regularRates;
        }
      }
    },
    filterRates(value, search) {
      if (value != null && search != null) {
        if (value.seconds) {
          return this.$moment(value.toDate()).format('DD-MM-YYYY').indexOf(search) !== -1;
        }
        return typeof value === 'string' && value.indexOf(search) !== -1
      }
    },
    deleteItem(item) {
      this.$dialog.confirm(`¿Desea eliminar la tarifa del <strong>${item.formatStartDate()} al ${item.formatEndDate()}</strong>?`, {
        loader: true // default: false - when set to true, the proceed button shows a loader when clicked.
        // And a dialog object will be passed to the then() callback
      })
      .then((dialog) => {
        this.tourModel.deleteRate(this.$route.params.tourId, item.id, item.type).then(() => {
          dialog.close();
          this.snackbar.show = true;
          this.snackbar.color = 'green';
          this.snackbar.message = `La tarifa se elimino correctamente`;
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          dialog.close();
          this.snackbar.show = true;
          this.snackbar.color = 'red';
          this.snackbar.message = `Error al eliminar la tarifa, por favor contacte al Administrador si el problema persiste`;
        });
      })
      .catch((e) => {
        console.log(e);
        // Triggered when cancel button is clicked
        console.log('Delete aborted');
      });
    }
  },
  mounted () {
    this.tourModel.findByTour(this.$route.params.tourId).onSnapshot(snap => {
      this.tour = {id:snap.id,_ref:snap.ref,... snap.data()};
    })
    this.seachLocalRatesAfterDate = this.tourModel.seachLocalRatesAfterDate(this.$route.params.tourId, this.date).onSnapshot(snap => {
      this.localRates = [];
      snap.forEach(doc => {
        let data = doc.data();
        data.moment = this.$moment;
        //data.ref = doc.ref;
        this.localRates.push(data);
      })
      this.setItems();
    })
    this.seachRegularRatesAfterDate = this.tourModel.seachRegularRatesAfterDate(this.$route.params.tourId, this.date).onSnapshot(snap => {
      this.regularRates = [];
      snap.forEach(doc => {
        let data = doc.data();
        data.moment = this.$moment;
        //data.ref = doc.ref;
        this.regularRates.push(data);
      })
      this.setItems();
    })
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  }
};
</script>
<style>
a {
  text-decoration: none;
  /* quita el subrayado de routerlink */
}
</style>