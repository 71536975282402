var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"title-page"}},[(_vm.tour)?_c('h1',[_vm._v(_vm._s(_vm.tour.name))]):_vm._e()]),(_vm.tour)?_c('tour-tabs',{attrs:{"tab":"rates","tour":_vm.tour}},[(_vm.items != null)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"item-key":"id","search":_vm.search,"fixed-header":"","custom-filter":_vm.filterRates},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-top-bar"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Buscar","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}}),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({path:("/backend/admin/tours/" + (_vm.$route.params.tourId) + "/rates/create")})}}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" Tarifa")],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatStartDate())+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatEndDate())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.push({path:("/backend/admin/tours/" + (_vm.$route.params.tourId) + "/rates/" + (item.id) + "/edit?type=" + (item.type))})}}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")])]}}],null,false,3259429804),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }